<template>
  <button class="btn btn-default btn-sm mt-4 px-5" @click="this.$router.go(-1)">
    Back
  </button>
  <div class="main-content" id="panel">
    <!-- Topnav -->
    <!-- Header -->
    <!-- Header -->
    <div
      class="header pb-6 d-flex align-items-center"
      :style="
        'min-height: 500px;background-image: url(' +
        backend_url +
        entity.wall_picture +
        ');background-size: cover;background-position: center top;'
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-default opacity-6"></span>
      <!-- Header container -->
      <div class="container-fluid w-100 d-flex align-items-center">
        <div class="row align-items-start w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-3 text-white">{{ entity.name }}</h1>
            <p class="text-white text-md mt-0 mb-5" v-html="entity.description "></p>
            <div class="d-flex">
              <a
                :href="entity.website"
                target="blank"
                class="btn btn-neutral text-default d-none d-md-block mr-2"
              >
                <em class="fas fa-globe"></em> Visit Website
              </a>
              <button
                type="button"
                class="btn btn-neutral text-default btn-icon-only rounded-circle d-block d-md-none mr-2"
              >
                <span class="btn-inner--icon">
                  <em class="fas fa-globe"></em>
                </span>
              </button>
              <span v-for="social in socials" :key="social.uuid">
                <a
                  v-if="social.name === 'Facebook'"
                  :href="social.link"
                  target="blank"
                  class="btn-facebook avatar avatar-sm rounded-circle mr-2"
                >
                  <em class="fab fa-facebook"></em>
                </a>
                <a
                  v-if="social.name === 'Twitter'"
                  :href="social.link"
                  target="blank"
                  class="avatar avatar-sm btn-twitter rounded-circle mr-2"
                >
                  <em class="fab fa-twitter"></em>
                </a>
                <a
                  v-if="social.name === 'Instagram'"
                  :href="social.link"
                  target="blank"
                  class="avatar avatar-sm btn-instagram rounded-circle mr-2"
                >
                  <em class="fab fa-instagram"></em>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-4 order-xl-last">
          <div class="card card-profile">
            <img
              :src="backend_url + entity.profileImage"
              class="card-img-top"
            />
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      :src="backend_url + entity.logo"
                      class="rounded-circle shadow-lg bg-white"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4 mt-2"
            >
            
            </div>

            <div class="card-body pt-0">
              <div class="row">
                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading"
                        >{{ entity.women_percentage }}%</span
                      >
                      <span class="description">Women Employees</span>
                    </div>
                    <div>
                      <span class="heading">{{ entity.no_of_products }}</span>
                      <span class="description">Products</span>
                    </div>
                    <div>
                      <span class="heading">{{ entity.no_of_services }}</span>
                      <span class="description">Services</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="h5 font-weight-300">
                  <em class="fas fa-location-arrow mr-2"></em
                  >{{ entity.address }}
                </div>
                <div class="h5 mt-4">
                  <em class="fas fa-phone mr-2"></em> {{ entity.phone }}
                </div>
                <div>
                  <em class="ni ni-email-83 mr-2"></em> {{ entity.email }}
                </div>
              </div>
            </div>
          </div>
          <!-- Members list group card -->
        </div>
        <div class="col-xl-8 order-xl-1">
          <div class="card shadow-lg">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Products</h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center py-lg-6">
                <p>Products will be listed Here</p>
              </div>
            </div>
          </div>
          <div class="card shadow-lg">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Services</h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center py-lg-6">
                <p>Services will be listed Here</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      slideTitle: "Explore Popular Categories",
      products: [],
      services: [],
      entity: {},
      contacts: [],
      backend_url: process.env.VUE_APP_BACKEND_URL,
      socials: [],
    };
  },
  methods: {
    fetch_socials: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/socials`
        )
        .then((res) => {
        
          this.socials = res.data;
        })
        .catch((err) => {
         if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired");
              this.$router.push({ name: "Login",})
            }
        });
    },
    get_details: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}`
        )
        .then((res) => {
          console.log(res);
          this.entity = res.data;
          this.entity.women_percentage = (
            (this.entity.no_of_women_employees * 100) /
            this.entity.no_of_employees
          ).toFixed(2);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.get_details();
    this.fetch_socials();
  },
};
</script>

<style>
</style>